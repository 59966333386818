













import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Coordinate, MapSettings } from 'client-website-ts-library/types';
import { Utils } from 'client-website-ts-library/util';
import { Config, Logger, LogLevel } from 'client-website-ts-library/services';

import mapboxgl from 'mapbox-gl';

let markers: mapboxgl.Marker[] = [];

@Component
export default class MapBoxMap extends Vue {
  @Prop()
  public readonly mapSettings!: MapSettings;

  public readonly elemId = `map_${Utils.GetGuid()}`;

  public map?: mapboxgl.Map;

  public isAttemptingToScroll = false;

  mounted() {
    mapboxgl.accessToken = Config.Website.Settings!.MapProvider.APIKey;

    this.mapSettings.Geocode().then(() => {
      let latMax = -90;
      let latMin = 90;
      let lngMax = -180;
      let lngMin = 180;

      this.mapSettings.Markers.forEach((marker) => {
        if (marker.Coordinate!.Lat > latMax) latMax = marker.Coordinate!.Lat;
        if (marker.Coordinate!.Lat < latMin) latMin = marker.Coordinate!.Lat;

        if (marker.Coordinate!.Lon > lngMax) lngMax = marker.Coordinate!.Lon;
        if (marker.Coordinate!.Lon < lngMin) lngMin = marker.Coordinate!.Lon;
      });

      let bounds = new mapboxgl.LngLatBounds(
        {
          lat: latMin,
          lng: lngMin,
        },
        {
          lat: latMax,
          lng: lngMax,
        },
      );

      Logger.Log(LogLevel.Debug, this.mapSettings.Markers, bounds);

      const mapSettings: mapboxgl.MapboxOptions = {
        container: this.elemId,
        style: Config.Website.Settings!.MapProvider.MapboxStyleURI,
        interactive: this.mapSettings.Interactive,
        zoom: this.mapSettings.Zoom,
        maxBounds: this.mapSettings.MaxBounds
          ? [
            [this.mapSettings.MaxBounds.SW.Lon, this.mapSettings.MaxBounds.SW.Lat],
            [this.mapSettings.MaxBounds.NE.Lon, this.mapSettings.MaxBounds.NE.Lat],
          ]
          : undefined,
      };

      if (this.mapSettings.Center) {
        mapSettings.center = { lat: this.mapSettings.Center.Lat, lng: this.mapSettings.Center.Lon };
      } else {
        mapSettings.fitBoundsOptions = { padding: this.mapSettings.Padding ?? 100, maxZoom: 15 };
        mapSettings.bounds = bounds;
      }

      this.map = new mapboxgl.Map(mapSettings);

      // TODO this is for suburb boundaries
      // this.map.on('load', () => {
      //   this.map.addSource('postal-4', {
      //     type: 'vector',
      //     url: 'mapbox://mapbox.boundaries-pos4-v4',
      //     promoteId: 'Cairns QLD 4870, Australia', // TODO this is not the correct ID
      //   });

      //   this.map.addLayer(
      //     {
      //       id: 'postal-4-fill',
      //       type: 'fill',
      //       source: 'postal-4',
      //       'source-layer': 'boundaries-pos4-v4',
      //       paint: {
      //         'fill-color': '#CCCCCC',
      //         'fill-opacity': 0.5,
      //       },
      //     },
      //     'waterway-label',
      //   );
      // });

      if (this.mapSettings.Interactive && !this.mapSettings.GreedyZoom && this.map) {
        this.map.scrollZoom.disable();

        let scrollDebounce: number | undefined;

        // Enable scroll zoom if we are using a touch device
        this.map.on('touchstart', () => {
          if (!this.map) return;

          this.map.scrollZoom.enable();
        });

        this.map.on('wheel', (event) => {
          if (!this.map) return;

          if (event.originalEvent.ctrlKey) {
            // Check if CTRL key is pressed
            event.originalEvent.preventDefault(); // Prevent chrome/firefox default behavior
            if (!this.map.scrollZoom.isEnabled()) {
              this.map.scrollZoom.enable(); // Enable zoom only if it's disabled
              this.isAttemptingToScroll = false;
            }
          } else {
            clearTimeout(scrollDebounce);
            scrollDebounce = window.setTimeout(() => {
              this.isAttemptingToScroll = false;
            }, 1000);

            this.isAttemptingToScroll = true;

            if (this.map.scrollZoom.isEnabled()) this.map.scrollZoom.disable(); // Disable zoom only if it's enabled
          }
        });
      }

      if (this.mapSettings.Markers.length > 0) {
        this.drawMarkers();
      }

      this.map.on('moveend', () => {
        bounds = (this.map?.getBounds() as unknown) as any;

        const ne = bounds?.getNorthEast();
        const sw = bounds?.getSouthWest();

        if (ne && sw) {
          this.$emit('moved', {
            NE: {
              Lat: ne.lat,
              Lon: ne.lng,
            },
            SW: {
              Lat: sw.lat,
              Lon: sw.lng,
            },
          });
        }

        return true;
      });
    });
  }

  @Watch('mapSettings.Markers')
  drawMarkers() {
    markers.forEach((m) => {
      m.remove();
    });

    markers = [];

    this.mapSettings.Markers.forEach((marker) => {
      const markerDiv = document.createElement('div');

      markerDiv.classList.add('mapbox-marker');

      if (marker.Content) markerDiv.classList.add('mapbox-marker--has-content');

      if (marker.CustomRenderer) {
        markerDiv.classList.add('mapbox-marker--custom');

        marker.CustomRenderer(markerDiv, marker);
      } else {
        markerDiv.style.backgroundImage = `url(${marker.IconURL || '/assets/images/map-icon.svg'})`;

        if (marker.Title) {
          const titleDiv = document.createElement('div');

          titleDiv.classList.add('mapbox-marker__title');
          titleDiv.innerHTML = marker.Title;

          markerDiv.appendChild(titleDiv);
        }
      }

      const mapMarker = new mapboxgl.Marker(markerDiv).setLngLat({ lat: marker.Coordinate!.Lat, lng: marker.Coordinate!.Lon });

      if (marker.Content) {
        mapMarker.setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(marker.Content));
      }

      mapMarker.addTo(this.map!);

      markers.push(mapMarker);
    });
  }

  public SetCenter(center: Coordinate, zoom: number) {
    if (this.map) {
      this.map.flyTo({
        center: {
          lat: center.Lat,
          lng: center.Lon,
        },
        zoom,
      });
    }
  }

  public GetZoom(): number {
    return this.map?.getZoom() ?? 4;
  }
}
